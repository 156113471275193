<template>
  <div class="user-logged" :class="{ login: currentUser }">
    <div class="user-logged__wrapper">
      <NuxtLink class="user-logged__wrapper--promotion" :to="'/khuyen-mai?type=event'">
        <BaseImg src="/assets/images/icon/icon-promotion.png" />
      </NuxtLink>
      <div class="user-logged__amount">
        <div class="user-logged__amount--tranfer">
          <div class="amount">
            <p>{{ userAmount }}</p>
            <BaseImg width="20" src="/assets/images/icon/amount.webp" />
          </div>
        </div>
        <div class="user-logged-avatar position-relative" @click="isShow = !isShow">
          <div class="user-logged-avatar__right">
            <p class="avatar">
              <BaseImg :src="avatarImage" alt="avatar" class="avatar-image" />
            </p>
          </div>
          <template v-if="isShow">
            <div class="dropdown__menu">
              <div
                v-for="(item, index) in USER_MENU"
                :key="index"
                class="dropdown__menu__form"
                :class="{'active': item.url === $route.fullPath }"
                @click.prevent="handleRedirect(item.url)"
              >
                <div class="recharge">
                  <span v-if="item.imgLight" class="icon" :class="item.imgLight" />
                  <BaseImg v-else :src="item.icon" />
                  <span class="text">{{ item.name }}</span>
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
      <BaseButton class="base-button--header button-deposit base-button--btn-primary" @click="onNavDeposit()">
        Nạp Tiền
      </BaseButton>
    </div>
  </div>
</template>

<script setup>
import BaseButton from '~/components/common/base-button.vue'
import { useLogout } from '~/composables/user/useLogout'
import { useHeader } from '~/composables/useHeader'
import BaseImg from '~/components/common/base-img.vue'
import { ACCOUNT_URLS } from '~/config/page-url'
import { USER_MENU } from '~/constants/menu'
const { $pinia } = useNuxtApp()

const { logout } = useLogout()
const router = useRouter()

const isShow = ref(false)
const { userAmount, currentUser, avatarImage } = useHeader()

const handleRedirect = (link) => {
  isShow.value = false
  if (link === 'logout') {
    logout()
  } else {
    router.push({ path: link })
  }
}

const onClickOutside = (e) => {
  if (
    !['user-logged-avatar', 'avatar-image', 'user-logged-avatar__welcome', 'user-logged-avatar__name'].includes(
      e.target.className
    )
  ) {
    isShow.value = false
  }
}

const onNavDeposit = () => {
  navigateTo(ACCOUNT_URLS.DEPOSIT_DA)
}

watch(isShow, () => {
  if (isShow) {
    window.addEventListener('click', onClickOutside)
  } else {
    window.removeEventListener('click', onClickOutside)
  }
})
</script>

<style scoped lang="scss">
@import 'assets/scss/components/desktop/layouts/header/user-login-block.scss';
</style>
