<template>
  <div class="header__button desktop">
    <NuxtLink class="btn-promotion" :to="'/khuyen-mai?type=event'">
      <BaseImg src="/assets/images/icon/icon-promotion.png" />
    </NuxtLink>
    <BaseButton class="base-button--btn-primary login" @click="onClickAuthor(MODAL_TYPES.LOGIN, 'login')">
      Đăng Nhập
    </BaseButton>
    <BaseButton
      class="register"
      @click="onClickAuthor(MODAL_TYPES.LOGIN, 'register')"
    >
      Đăng Ký
    </BaseButton>
  </div>
</template>

<script setup>
import BaseButton from '~/components/common/base-button.vue'
import BaseImg from '~/components/common/base-img.vue'
import { useHeader } from '~/composables/useHeader'

const { onClickAuthor, MODAL_TYPES } = useHeader()

</script>

<style scoped lang="scss">
@import 'assets/scss/components/desktop/layouts/header/user-not-login-block.scss';
</style>
