<template>
  <div class="menu">
    <div
      v-for="(item, index) in listLink"
      :id="`item-${index}`"
      :key="index"
      class="menu__item"
      :class="{ active: checkActiveMenu(item), show: subMenuIndex === index }"
      @click="onClickLink(item)"
      @mouseenter="onMouseOver(index)"
      @mouseleave="onMouseLeave()"
    >
      <span>{{ item.title }}</span>
      <Submenu
        v-if="item.subMenu.length"
        :submenu="item.subMenu"
        :game-type="item.type"
        :on-click-item="(subItem) => onClickLink(item, subItem)"
      />
    </div>
  </div>
</template>

<script setup>
import { storeToRefs } from 'pinia'
import queryString from 'query-string'
import { useAppStore } from '~/store/app'
import { useGameStore } from '~/store/game'
import useModal from '~/composables/useModal'
import { useGame } from '~/composables/game/useGame'
import { useCasino } from '~/composables/casino/useCasino'
import Submenu from '~/components/desktop/layouts/header/submenu.vue'
import { PAGE_URLS } from '~/config/page-url'
import { LOBBY_SPORTS } from '~/constants/menu'
const route = useRoute()
const { $pinia } = useNuxtApp()
const store = useAppStore($pinia)
const storeGames = useGameStore($pinia)
const { currentUser, isLogged } = storeToRefs(store)
const { providerGames, providerCasino, lotteryGameSubmenu, jackpotGameSubmenu, tableGameSubmenu } =
  storeToRefs(storeGames)
const { openModal } = useModal()
const router = useRouter()
const subMenuIndex = ref(null)
const { fetchCongGame, fetchGames } = useGame()
const { fetchGameCasino } = useCasino()

onMounted(() => fetchData())

const fetchData = () => {
  if (providerGames.value.length === 0) {
    fetchCongGame()
  }
  if (providerCasino.value.length === 0) {
    fetchGameCasino()
  }
  if (lotteryGameSubmenu.value.length === 0) {
    fetchGamesByCode('quay-so')
  }
  if (jackpotGameSubmenu.value.length === 0) {
    fetchGamesByCode('no-hu')
  }
  if (tableGameSubmenu.value.length === 0) {
    fetchGamesByCode('table-game')
  }
}

const fetchGamesByCode = async (alias) => {
  const query = queryString.stringify({ path_alias: alias, limit: 8, page: 1, sort_by: 'HOT_GAME' })
  const { data: response } = await fetchGames(query)
  switch (alias) {
    case 'table-game':
      storeGames.setTableGameSubmenu(response.data.items)
      break
    case 'quay-so':
      storeGames.setLotteryGameSubmenu(response.data.items)
      break
    case 'no-hu':
      storeGames.setJackpotGameSubmenu(response.data.items)
      break
  }
}

const checkActiveMenu = (item) => {
  if (!item) {
    return false
  }
  const providers = item.subMenu?.map((e) => e.alias) || []
  const { fullPath, params } = route
  const excludedTypes = ['quay-so', 'table-game', 'no-hu']

  switch (item.type) {
    case 'livecasino':
      return fullPath.includes(PAGE_URLS.CASINO)
    case 'games':
      return providers.includes(params?.type) && !excludedTypes.includes(params?.type)
    default:
      return fullPath.includes(item.link)
  }
}

const listLink = reactive([
  {
    type: 'sports',
    title: 'Thể Thao',
    link: PAGE_URLS.SPORT,
    subMenu: LOBBY_SPORTS
  },
  {
    type: 'livecasino',
    title: 'Live Casino',
    link: PAGE_URLS.CASINO,
    subMenu: []
  },
  // {
  //   type: 'da-ga',
  //   title: 'Đá Gà',
  //   link: PAGE_URLS.GAME + '/da-ga',
  //   subMenu: []
  // },
  {
    type: 'quay-so',
    title: 'Quay Số',
    link: PAGE_URLS.GAME + '/quay-so',
    subMenu: []
  },
  // {
  //   type: 'table-game',
  //   title: 'Table Game',
  //   link: PAGE_URLS.GAME + '/table-game',
  //   subMenu: []
  // },
  {
    type: 'no-hu',
    title: 'Nổ hũ',
    link: PAGE_URLS.GAME + '/no-hu',
    subMenu: []
  },
  {
    type: 'games',
    title: 'Cổng Game',
    link: PAGE_URLS.GAME + '/' + (providerGames?.value?.length > 0 ? providerGames?.value[0]?.alias : 'game-bai'),
    subMenu: []
  }
])

watchEffect(() => {
  const updateSubMenu = (submenu, type) => {
    const targetItem = listLink.find((item) => item.type === type)
    if (targetItem && submenu?.length) {
      targetItem.subMenu = isLogged.value ? submenu : submenu.filter((item) => item?.alias !== 'recently')
    }
  }

  const subMenusToUpdate = [
    { submenu: providerCasino.value, type: 'livecasino' },
    { submenu: lotteryGameSubmenu?.value, type: 'quay-so' },
    { submenu: tableGameSubmenu?.value, type: 'table-game' },
    { submenu: jackpotGameSubmenu?.value, type: 'no-hu' },
    { submenu: providerGames.value, type: 'games' }
  ]
  subMenusToUpdate.forEach(({ submenu, type }) => updateSubMenu(submenu, type))
})

function onClickLink(item, subItem = null) {
  subMenuIndex.value = null
  const link = subItem ? getSubItemLink(item.type, subItem?.alias?.toLowerCase()) : item.link
  router.push(link)
}

function getSubItemLink(type, code) {
  switch (type) {
    case 'livecasino':
      return `${PAGE_URLS.CASINO}/${code}`
    case 'games':
      return `${PAGE_URLS.GAME}/${code}`
    case 'sports':
      return code
  }
}

const onMouseOver = (index) => (subMenuIndex.value = index)
const onMouseLeave = () => (subMenuIndex.value = null)
</script>

<style scoped lang="scss">
@import 'assets/scss/components/desktop/layouts/header/header.scss';
</style>
