<template>
  <footer v-show="!isShowFooter" class="footer" :class="{ 'footer-custom': isCollapse }">
    <div class="footer-top">
      <div class="container" />
    </div>
    <div class="footer-bottom">
      <div class="container">
        <div class="footer-infor">
          <div class="footer-infor__left">
            <BaseImg
              id="footer-logo"
              src="/assets/brand/logo.svg"
              alt=""
              class="footer-logo"
              @click="$router.push('/')"
            />
            <p>
              {{ $config.public.BRANCH_NAME }} - Nhà cái đẳng cấp Châu Âu. {{ $config.public.BRANCH_NAME }} đem đến cho
              khách hàng dịch vụ cá cược trực tuyến hàng đầu với hàng trăm sản phẩm cược hấp dẫn như: Thể Thao, Thể Thao
              Ảo, Casino, Number Game...
            </p>
          </div>
          <div class="footer-infor__center footer-partner">
            <p class="footer-title">PHƯƠNG THỨC THANH TOÁN</p>
            <div class="footer-payment-method">
              <nuxt-link
                v-for="(item, index) in footer.footerPaymentMethods"
                id="payment-method"
                :key="index"
                class="footer-payment-method__item"
                :to="item.link"
                target="_blank"
                @click.prevent="redirectLink(item.link)"
              >
                <BaseImg id="payment-method-icon" :src="item.imgSrc" alt="icon" width="28" height="28" />
                <span>{{ item.text }}</span>
              </nuxt-link>
            </div>
          </div>
          <div class="footer-infor__right">
            <p class="footer-title">Về chúng tôi</p>
            <div class="footer-contact">
              <div class="footer-contact--row">
                <a
                  v-for="(contact, index) in contacts"
                  :key="index"
                  class="footer-contact--item"
                  :href="contact.url"
                  target="_blank"
                >
                  <BaseImg
                    id="contact-icon"
                    :src="contact.img"
                    :alt="contact.display_name"
                    class="icon"
                    width="24"
                    height="24"
                  />
                  <span>{{ contact.name }}</span>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="footer-provider_list">
          <div class="ticker">
            <div class="list-provider">
              <div class="list-provider__wrapper">
                <nuxt-link
                  v-for="(item, index) in footer.footerPartner"
                  id="partner"
                  :key="index"
                  :to="item.link"
                  class="provider-item"
                >
                  <BaseImg v-if="!item.href" id="partner-logo" :src="item.imgSrc" alt="logo partner" />
                </nuxt-link>
              </div>
            </div>
            <div class="list-provider">
              <div class="list-provider__wrapper">
                <nuxt-link
                  v-for="(item, index) in footer.footerPartner"
                  id="partner"
                  :key="index"
                  :to="item.link"
                  class="provider-item"
                >
                  <BaseImg v-if="!item.href" id="partner-logo" :src="item.imgSrc" alt="logo partner" />
                </nuxt-link>
              </div>
            </div>
          </div>
        </div>
        <div class="footer-copyright">
          <p>Copyright © 2021 {{ $config.public.BRANCH_NAME }}. All rights reserved.</p>
          <nuxt-link
            v-for="(item, index) in footer.footerMenu"
            id="footer-menu"
            :key="index"
            :to="item.link"
            class="footer-link"
          >
            {{ item.text }}
          </nuxt-link>
        </div>
      </div>
    </div>
  </footer>
</template>

<script setup>
import { footer } from '@/resources/footer'
import BaseImg from '~/components/common/base-img.vue'
import { useContact } from '~/composables/home/useContact'
import { useHeader } from '~/composables/useHeader'
import useModal from '~/composables/useModal'
import { SPORTS_URLS } from '~/config/page-url'

const { $config } = useNuxtApp()
const { fetchBranchContact, contacts } = useContact()
const router = useRouter()
const { openModalWithNavigate } = useModal()
const { MODAL_TYPES, currentUser } = useHeader()
const route = useRoute()

onMounted(async () => {
  await fetchBranchContact()
})

const props = defineProps({
  isCollapse: {
    type: Boolean,
    default: false
  }
})

const handleClick = (contact) => {
  if (contact.name.lowerCase() === 'live chat') {
    const openNewTab = window.open('about:blank', '_blank')
    openNewTab.location.href = contact.url
  }
}

const redirectLink = (link) => {
  if (currentUser.value === null) {
    openModalWithNavigate(MODAL_TYPES.LOGIN, 'login', link)
  } else {
    router.push(link)
  }
}
const isShowFooter = computed(() => {
  const values = Object.values(SPORTS_URLS).slice(1)
  return values.includes(route.path)
})
</script>

<style lang="scss" scoped src="assets/scss/components/desktop/layouts/footer/footer.scss"></style>
