<template>
  <header v-show="!isShowFooter" class="header desktop">
    <div class="header-container container">
      <div class="header-wrapper">
        <div class="header-left">
          <LogoHeader />
        </div>
        <div class="header-right">
          <MenuHeader />
        </div>
      </div>
      <div class="header-action">
        <client-only>
          <UserLoginBlock v-if="isLogged" />
          <UserNotLoginBlock v-else />
        </client-only>
      </div>
    </div>
  </header>
</template>
<script setup>
import { storeToRefs } from 'pinia'
import LogoHeader from './logo.vue'
import MenuHeader from './menu.vue'
import UserLoginBlock from './user-login-block.vue'
import UserNotLoginBlock from './user-not-login-block.vue'
import { useAppStore } from '~/store/app'
import { SPORTS_URLS } from '~/config/page-url'

const { $pinia } = useNuxtApp()
const store = useAppStore($pinia)
const { isLogged } = storeToRefs(store)
const route = useRoute()

const isShowFooter = computed(() => {
  const values = Object.values(SPORTS_URLS).slice(1)
  return values.includes(route.path)
})

</script>
<style scoped lang="scss">
@import 'assets/scss/components/desktop/layouts/header/index.scss';
</style>
