import { ACCOUNT_URLS, PAGE_URLS } from '~/config/page-url'
import { PATH_FOOTER_URL } from '~/constants/path'
import { IFooter } from '~/types/footer'

export const footer: IFooter = {
  footerMenu: [
    {
      link: PAGE_URLS.PROMOTION,
      text: 'Khuyến mãi',
      label: ''
    },
    {
      link: PAGE_URLS.INTRODUCTION + '/nha-cai',
      text: 'Giới thiệu',
      label: ''
    },
    {
      link: PAGE_URLS.POLICY,
      text: 'Điều khoản điều kiện',
      label: ''
    },
    // {
    //   link: PAGE_URLS.GUIDE_WITHDRAW,
    //   text: 'Hướng dẫn nạp rút',
    //   label: ''
    // },
    {
      link: PAGE_URLS.GUIDE_REGISTER,
      text: 'Hướng dẫn đăng ký',
      label: ''
    },
    {
      link: PAGE_URLS.NEWS,
      text: 'Tip bóng đá',
      label: ''
    },
    {
      link: PAGE_URLS.NEWS + '/the-loai/soi-keo-bong-ro?page=1',
      text: 'Soi kèo',
      label: ''
    }
  ],
  footerBank: [
    {
      imgSrc: `${PATH_FOOTER_URL}bank/bank_donga.png`
    },
    {
      imgSrc: `${PATH_FOOTER_URL}bank/bank_sacom.png`
    },
    {
      imgSrc: `${PATH_FOOTER_URL}bank/bank_acb.png`
    },
    {
      imgSrc: `${PATH_FOOTER_URL}bank/bank-mb.png`
    },
    {
      imgSrc: `${PATH_FOOTER_URL}bank/bank_bidv.png`
    },
    {
      imgSrc: `${PATH_FOOTER_URL}bank/vp-bank.svg`
    },
    {
      imgSrc: `${PATH_FOOTER_URL}bank/bank_vietcom.png`
    },
    {
      imgSrc: `${PATH_FOOTER_URL}bank/bank_techcom.png`
    },
    {
      imgSrc: `${PATH_FOOTER_URL}bank/bank_vietin.png`
    },
    {
      imgSrc: `${PATH_FOOTER_URL}bank/bank_exim.webp`
    },
    {
      imgSrc: `${PATH_FOOTER_URL}bank/bank_tp.webp`
    },
    {
      imgSrc: `${PATH_FOOTER_URL}bank/bank_shb.svg`
    }
  ],
  footerPartner: [
    {
      imgSrc: `${PATH_FOOTER_URL}partner/go88.webp`,
      class: 'go88'
    },
    {
      imgSrc: `${PATH_FOOTER_URL}partner/rik.webp`,
      class: 'b52'
    },
    {
      imgSrc: `${PATH_FOOTER_URL}partner/b52.webp`
    },
    {
      imgSrc: `${PATH_FOOTER_URL}partner/iwin.webp`
    },
    {
      imgSrc: `${PATH_FOOTER_URL}partner/techplay.webp`
    },
    {
      imgSrc: `${PATH_FOOTER_URL}partner/saba.webp`
    },
    {
      imgSrc: `${PATH_FOOTER_URL}partner/bestsoft.webp`
    },
    {
      imgSrc: `${PATH_FOOTER_URL}partner/tomhorn.webp`
    },
    {
      imgSrc: `${PATH_FOOTER_URL}partner/spinomenal.webp`
    },
    {
      imgSrc: `${PATH_FOOTER_URL}partner/bbtech.webp`,
      class: 'BBTech'
    },
    {
      imgSrc: `${PATH_FOOTER_URL}partner/playngo.webp`,
      class: 'playngo'
    },
    {
      imgSrc: `${PATH_FOOTER_URL}partner/spribe.webp`
    },
    {
      imgSrc: `${PATH_FOOTER_URL}partner/pragmaticplay.webp`
    },
    {
      imgSrc: `${PATH_FOOTER_URL}partner/cq9.webp`
    },
    {
      imgSrc: `${PATH_FOOTER_URL}partner/microgaming.webp`
    },
    {
      imgSrc: `${PATH_FOOTER_URL}partner/evoplay.webp`
    },
    {
      imgSrc: `${PATH_FOOTER_URL}partner/qtech.webp`
    },
    {
      imgSrc: `${PATH_FOOTER_URL}partner/habanero.webp`
    },
    {
      imgSrc: `${PATH_FOOTER_URL}partner/onegame.webp`
    },
    {
      imgSrc: `${PATH_FOOTER_URL}partner/betradar.webp`
    },
    {
      imgSrc: `${PATH_FOOTER_URL}partner/redtiger.webp`
    },
    {
      imgSrc: `${PATH_FOOTER_URL}partner/netent.webp`
    },
    {
      imgSrc: `${PATH_FOOTER_URL}partner/jdpgaming.webp`
    },
    {
      imgSrc: `${PATH_FOOTER_URL}partner/wazdan.webp`
    },
    {
      imgSrc: `${PATH_FOOTER_URL}partner/pg.webp`
    },
    {
      imgSrc: `${PATH_FOOTER_URL}partner/simpleplay.webp`
    },
    {
      imgSrc: `${PATH_FOOTER_URL}partner/pariplay.webp`
    },
    {
      imgSrc: `${PATH_FOOTER_URL}partner/sagaming.webp`
    },
    {
      imgSrc: `${PATH_FOOTER_URL}partner/yeebet.webp`
    },
    {
      imgSrc: `${PATH_FOOTER_URL}partner/jili.webp`
    },
    {
      imgSrc: `${PATH_FOOTER_URL}partner/btg.webp`
    },
    {
      imgSrc: `${PATH_FOOTER_URL}partner/nonimitcity.webp`
    },
    {
      imgSrc: `${PATH_FOOTER_URL}partner/fachai.webp`
    },
    {
      imgSrc: `${PATH_FOOTER_URL}partner/askmebet.webp`
    },
    {
      imgSrc: `${PATH_FOOTER_URL}partner/askmelotto.webp`
    },
    {
      imgSrc: `${PATH_FOOTER_URL}partner/digmaan.webp`
    },
    {
      imgSrc: `${PATH_FOOTER_URL}partner/kingmaker.webp`
    },
    {
      imgSrc: `${PATH_FOOTER_URL}partner/nextspin.webp`
    },
    {
      imgSrc: `${PATH_FOOTER_URL}partner/hacksawgaming.webp`
    },
    {
      imgSrc: `${PATH_FOOTER_URL}partner/oneapi.webp`
    },
    {
      imgSrc: `${PATH_FOOTER_URL}partner/dreamgaming.webp`
    }
  ],
  footerPaymentMethods: [
    {
      text: 'CodePay',
      imgSrc: `${PATH_FOOTER_URL}payment-methods/codepay.svg`,
      link: ACCOUNT_URLS.DEPOSIT_DA
    },
    {
      text: 'P2P',
      imgSrc: `${PATH_FOOTER_URL}payment-methods/p2p.svg`,
      link: ACCOUNT_URLS.P2P
    },
    {
      text: 'Tiền ảo',
      imgSrc: `${PATH_FOOTER_URL}payment-methods/tien-ao.svg`,
      link: ACCOUNT_URLS.DEPOSIT_CRYPTO
    },
    {
      text: 'Ví điện tử',
      imgSrc: `${PATH_FOOTER_URL}payment-methods/momo.svg`,
      link: ACCOUNT_URLS.DEPOSIT_MOMO
    },
    {
      text: 'Viettel Money',
      imgSrc: `${PATH_FOOTER_URL}payment-methods/viettel-money.svg`,
      link: ACCOUNT_URLS.DEPOSIT_VIETTEL_PAY
    },
    {
      text: 'Thẻ cào',
      imgSrc: `${PATH_FOOTER_URL}payment-methods/the-cao.svg`,
      link: ACCOUNT_URLS.DEPOSIT_CARD
    }
  ]
}
